<template>
  <div>
    <div class="search">
      <div class="searchFl">
        <portSelect
          v-model="desc"
          placeTitle="请输入目的港"
          clearable
          @input="dest_port_input"
        />
        <el-date-picker
        :clearable='true'
        style="margin-left:20px"
          v-model="time"
          type="daterange"
          size="small"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div>
        <el-button size="small" type="primary" @click="searchChange"
          >查询</el-button
        >
      </div>
    </div>
    <div class="line"></div>
    <div class="tableBlock">
      <el-table
        :data="tableData"
        style="width: 100%"
        :height="tableHeight"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <el-table-column
          label="港口信息"
          width="300"
          :show-overflow-tooltip="true"
        >
          <div slot-scope="scope" class="protMsg">
            <div class="from">
              <p class="textOverflow"  style="color:#91be42">{{ scope.row.from_port_en | textFormat }}</p>
              <p class="textOverflow">{{ scope.row.from_port_zh | textFormat }}</p>
            </div>
            <div class="jt" style="color:#7D8695">>>></div>
            <div class="dest">
              <p class="textOverflow"  style="color:#91be42">{{ scope.row.dest_port_en | textFormat }}</p>
              <p class="textOverflow">{{ scope.row.dest_port_zh | textFormat }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="中转港" :show-overflow-tooltip="true">
          <div slot-scope="scope">
            {{
              scope.row.trans_port_en ||
              scope.row.trans_port ||
              "直达" | textFormat
            }}
          </div>
        </el-table-column>
        <el-table-column label="船公司" :show-overflow-tooltip="true">
          <div slot-scope="scope" class="shipping">
            <el-image
              style="width:25px; height:25px"
              :src="scope.row.logo||default_logo"
              fit="cover"></el-image>
            <span></span>
            {{ scope.row.shipping_company | textFormat }}
          </div>
        </el-table-column>
        <el-table-column label="货物类型" :show-overflow-tooltip="true">
          <div slot-scope="scope">
            {{ scope.row.is_dangerous ? "危险品" : "普货" | textFormat }}
          </div>
        </el-table-column>
        <el-table-column label="船期" :show-overflow-tooltip="true">
          <div slot-scope="scope">
            {{ scope.row.shipping_date | weekDaysFormat }}
          </div>
        </el-table-column>

        <el-table-column label="箱货信息" :show-overflow-tooltip="true">
          <div slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.container"
              :key="index"
            >
               <span v-if="item.number&&item.price!=0"> {{ item.name }} &nbsp; </span>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="海运费（USD）" :show-overflow-tooltip="true">
          <div slot-scope="scope">
            <span v-if="scope.row.is_quote==0" style="color:#FF6514">待报价</span>
            <span v-if="scope.row.is_quote==2" style="color:#C8C8C8">已过期</span>
            <div v-if="scope.row.is_quote==1" style="color:#91BE42">
                <div
                  v-for="(item, index) in scope.row.container"
                  :key="index"
                >
                <span v-if="item.number&&item.price!=0">  {{ item.code }} {{ item.price }} &nbsp; </span>
              </div>
            </div>
            
          </div>
        </el-table-column>
        <el-table-column label="锁价方式" :show-overflow-tooltip="true" width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.lock_price_way==1 || scope.row.lock_price_way==2">
              {{scope.row.validity_start | secondFormat('L1')}} ~ {{scope.row.validity_end | secondFormat('L1')}}
            </span>
            <span v-else-if="scope.row.lock_price_way==3">{{scope.row.vessel | textFormat}} / {{scope.row.voyage |textFormat}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="" :show-overflow-tooltip="true" width="100">
          <div slot-scope="scope">
            <el-button type="text" @click="gotoDetail(scope.row)"
              >询价详情</el-button
            >
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        @current-change="handleChange"
        :page-size="15"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import portSelect from "~ymp/components/common/portSelect";

export default {
  name: "myofferList",
  components: { portSelect },
  data() {
    return {
      default_logo:"https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/miniProgram/ship.png?versionId=CAEQIxiBgMCgot3e0BciIDczYThjOWU5NzZlYzRmOTA4Yzk4Yjk0MDUwZWMxZmUz",
      loading: false,
      total: 0,
      currentPage: 1,
      tableHeight: 100,
      tableData: [],
      desc: "",
      desc_id: "",
      time: "",
    };
  },
  mounted() {
    this.$log({
      type: 2,
      topic: "ymp.workbench",
      id: "ymp.workbench.inquire",
    });
    this.getList();
  },
  methods: {
    gotoDetail(row){
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.inquire.detail",
        content: JSON.stringify({
          id: row.inquiry_id || ''
        }),
      });
      this.$router.push({
          query: {
              inquiry_id: row.inquiry_id,
          },
          path: "/console/myoffer/customer_inquiry_detail",
      })
    },
    dest_port_input(val) {
      if (val === "") val = null;
      let num = Number(val);
      if (!isNaN(num) || val === "") {
        this.desc_id = val;
      }
    },
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let params = {
        sales_id: this.USER_INFO.id,
        limit: 15,
        start: this.currentPage - 1,
        dest_port:this.desc_id,
        source: 2,
        nopage:0
      };
      if(this.time&&this.time.length){
         params.start_time=this.$moment(this.time[0]).unix()
         params.end_time=this.$moment(this.time[1]).unix()+86399
      }
      let result = await this.$store.dispatch( //
        "baseConsole/sell_inquiry",
        params
      );
      this.loading = false;
      if (result && result.success) {
        result.data.forEach((element) => {
          let now = this.$moment().unix()
          if (element.is_quote == 0 && element.validity_end < now) {
            element.is_quote = 2
          }

          element.container = JSON.parse(element.container).splice(0,3) ;
          let money = 0;
          element.container.forEach((ee) => {
            if (ee.number) {
              money = money + ee.price;
            }
          });
          element.seaMoney = money;
        });
        this.tableData = result.count ? result.data : [];
        this.total = result.count;
      }
    },
    searchChange() {
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.inquire.search",
        content: JSON.stringify({
          pod: this.desc_id || '',
          start_time: this.time&&this.time.length ? this.$moment(this.time[0]).unix() : '',
          end_time: this.time&&this.time.length ? this.$moment(this.time[1]).unix()+86399 : '',
        }),
      });
      this.tableData = [];
      this.currentPage = 1;
      this.getList();
    },
  },
};
</script>
<style scoped lang="less">
.protMsg {
  display: flex;
  justify-content: space-between;
  .from {
    width: 100px;
    p{
      width: 100%;
    }
  }
  .jt {
    width: 50px;
    color: #027fff;
    line-height: 50px;
  }
  .dest {
    width: 100px;
    p{
      width: 100%;
    }
  }
}
.search {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: space-between;

  .searchFl {
    width: 90%;
    display: flex;
    align-items: center;

    .searchInput {
      width: 15%;
      padding: 0 6px;
    }
  }
}
.line {
  height: 1px;
  width: 100%;
  background: #ccc;
  margin-bottom: 20px;
}
.shipping{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
</style>